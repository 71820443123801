/* custom-night-mode.scss */
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

// Define your custom table row colors for the night mode
[data-bs-theme="dark"] {
  .table .table-success {
    --bs-table-bg: #225425 !important; /* Darker green */
    --bs-table-color: #ffffff !important;
    --bs-table-border-color: var(--bs-border-color) !important;
  }
  .table .table-warning {
    --bs-table-bg: #886a28 !important; /* Darker orange */
    --bs-table-color: #ffffff !important;
    --bs-table-border-color: var(--bs-border-color) !important;
  }
  .table .table-info {
    --bs-table-bg: #3f7593 !important; /* Darker blue */
    --bs-table-color: #ffffff !important;
    --bs-table-border-color: var(--bs-border-color) !important;
  }

  /* For striped rows */
  .table.table-striped tbody tr:nth-of-type(odd) {
    --bs-table-striped-color: #ffffff !important;
    --bs-table-striped-bg: rgba(255, 255, 255, 0.05); /* Darker stripe */
  }

  /* For hover state */
  .table.table-hover tbody tr:hover {
    --bs-table-hover-color: #ffffff !important;
    --bs-table-hover-bg: rgba(255, 255, 255, 0.1); /* Darker hover */
  }

  /* Additional hover customization for table-success, table-warning, and table-info */
  .table.table-hover tbody tr.table-success:hover {
    --bs-table-hover-bg: #356b2c !important; /* Darker hover for success */
  }
  .table.table-hover tbody tr.table-warning:hover {
    --bs-table-hover-bg: #9a7930 !important; /* Darker hover for warning */
  }
  .table.table-hover tbody tr.table-info:hover {
    --bs-table-hover-bg: #28698a !important; /* Darker hover for info */
  }
}
